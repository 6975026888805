import { SystemEvent } from '@mbrtargeting/metatag-shared-types/metatag-core';
import { isArray, loadScript } from '@mbrtargeting/metatag-utils';
import { inject, injectionTarget } from '../decorators/inject.js';
import { onSystemEvent } from '../decorators/on-system-event.js';
import { ConfigResolver } from '../essentials/config-resolver.js';
import { logger } from '../essentials/logger.js';
import { CONFIG_RESOLVER } from '../token.js';

const log = logger.logGroup({ prefix: 'DependencyLoader' });

@injectionTarget()
export class DependencyLoader {

    @inject(CONFIG_RESOLVER) #configResolver!: ConfigResolver;

    /**
     * will load additional scripts
     */
    @onSystemEvent(SystemEvent.SDG_CONFIG_FILE_AVAILABLE)
    public loadDependencies(): void {
        const scripts: string[] | undefined = this.#configResolver.get(config => config.dependencies?.scripts);
        if (!isArray(scripts)) return;

        scripts.map(src => loadScript({
            src,
            node: document.head,
            onload: () => log.debug('Script %o loaded successfully', [src]),
            onerror: (event) => log.error('Script %o was not loaded. Check DevTools for further information.', [event]),
        }));
    }
}
