import React, { FC } from 'react'
import FontelloIcon from '@sport1/news-styleguide/FontelloIcon'
import { NABVARMOBILECONFIG } from '@/utils/navigation/navigationData'

type Props = {
    toggleHidden: () => void
}

const BurgerMenu: FC<Props> = ({ toggleHidden }) => {
    return (
        <div className="z-10 block lg:hidden">
            <button
                className="flex h-full items-center border-gray-600 px-3 py-2 text-gray-500 hover:border-white hover:text-white"
                onClick={toggleHidden}
            >
                <FontelloIcon
                    name={NABVARMOBILECONFIG.BURGERMENU.IMAGE}
                    color="#fff"
                    fontSize="18px"
                />
            </button>
        </div>
    )
}

export default BurgerMenu
