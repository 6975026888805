import { TCData } from '@mbrtargeting/metatag-cmp-types';

export interface ConsentCondition {
    gdprApplies?: boolean;
    consentedPurposes?: number[];
    consentedVendors?: number[];
    legitimateInterestPurposes?: number[];
    legitimateInterestVendors?: number[];
}

/**
 * Helper util to evaluate given consent conditions against an given tcData object.
 *
 * All given `ConsentCondition`'s are OR-linked.
 * Properties within an `ConsentCondition` are AND-linked.
 *
 * @example [
 *     { consentedPurposes: [1,4], consentedVendors: [1057] },
 *     { legitimateInterestPurposes: [4], legitimateInterestVendors: [1057] },
 *     { gdprApplies: false },
 * ]
 *
 * @param tcData consent data from cmp
 * @param conditions an array of consent conditions to check
 * @returns true, if one of the consent conditions evaluate to true
 */
export const evaluateConsent = (tcData: TCData | undefined, conditions: ConsentCondition[]): boolean => {
    const isGdprApplies = () => !!tcData?.gdprApplies;
    const hasPurposeConsent = (purposeId: number) => !!tcData?.purpose?.consents?.[purposeId];
    const hasVendorConsent = (vendorId: number) => !!tcData?.vendor?.consents?.[vendorId];
    const hasPurposeLegitimateInterest = (purposeId: number) => !!tcData?.purpose?.legitimateInterests?.[purposeId];
    const hasVendorLegitimateInterest = (vendorId: number) => !!tcData?.vendor?.legitimateInterests?.[vendorId];

    const hasConsent = ({ gdprApplies = true, consentedPurposes = [], consentedVendors = [], legitimateInterestPurposes = [], legitimateInterestVendors = [] }: ConsentCondition): boolean => {
        const shouldBeAllTrue: boolean[] = [
            gdprApplies === isGdprApplies(),
            consentedPurposes.every(hasPurposeConsent),
            consentedVendors.every(hasVendorConsent),
            legitimateInterestPurposes.every(hasPurposeLegitimateInterest),
            legitimateInterestVendors.every(hasVendorLegitimateInterest),
        ];
        return shouldBeAllTrue.every(v => v);
    };

    return conditions.some(hasConsent);
}
