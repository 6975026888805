import { Entry, Map2 } from './map2.js';

export type ValueType = string[] | Values<string>
export type KeyValuePairsType = Record<string, string[] | Values<string>>

/**
 * A set of values (ES2015) plus some additional helper functions.
 *
 * The `Set` object lets you store unique values of any type (automatic deduplication).
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set
 */
export class Values<T> extends Set<T> {

    /**
     * Appends multiple values to the Values/Set object.
     *
     * @param values an array or set of values to add
     * @returns the `Values` object with added values
     */
    public addAll(values: T[] | Set<T>): this {
        for (const value of Array.from(values)) this.add(value);
        return this;
    }

    /**
     * Removes multiple values from the Values/Set object (if present).
     *
     * @param values an array or set of values to remove
     * @returns the `Values` object with removed values
     */
    public deleteAll(values: T[] | Set<T>): this {
        for (const value of Array.from(values)) this.delete(value);
        return this;
    }

    /**
     * Converts the Values/Set object into an array.
     *
     * @returns an array of elements
     */
    public toArray(): T[] {
        return Array.from(this);
    }

    /**
     * Returns the first value of the Values/Set object.
     *
     * @returns the first value or `undefined` if empty
     */
    public first(): T | undefined {
        for (const value of this) return value;
    }
}

/**
 * A map of values per key plus some additional helper functions.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map
 */
export class KeyValues extends Map2<string, Values<string>> {

    constructor() {
        super(() => new Values());
    }

    /**
     * Shortcut to add multiple values for multiple keys.
     *
     * @param keyValues {KeyValuePairsType} key-value pairs
     */
    public addKeyValues(keyValues: KeyValuePairsType): void {
        Object.entries(keyValues).forEach(([key, values]) => this.addValues(key, values));
    }

    /**
     * Shortcut to set multiple values for multiple keys (replaces existing values).
     *
     * @param keyValues key-value pairs
     */
    public setKeyValues(keyValues: KeyValuePairsType): void {
        Object.entries(keyValues).forEach(([key, values]) => this.setValues(key, values));
    }

    /**
     * Adds multiple values (array or set) for a given key.
     *
     * @param key a given key
     * @param values array or set of values
     */
    public addValues(key: string, values: ValueType): void {
        this.get(key).addAll(values);
    }

    /**
     * Sets multiple values (array or set) for a given key (replaces existing values).
     *
     * @param key
     * @param values
     */
    public setValues(key: string, values: ValueType): void {
        this.set(key, new Values(values));
    }

    /**
     * Removes multiple values (array or set) for a given key.
     * If no value is left, the key is removed.
     */
    public deleteValues(key: string, values?: ValueType) {
        const rest = values && this.get(key)?.deleteAll(values);
        if (!rest?.size) this.delete(key);
    }

    /**
     * Returns a filtered copy of `KeyValues`
     *
     * @param predicate condition to meet
     * @returns a new KeyValues object
     */
    public filter(predicate: ([key, values]: Entry<string, Values<string>>) => boolean): KeyValues {
        // TODO filter values not only keys
        return this.entriesArray().filter(predicate).reduce((result, [key, values]) => (result.setValues(key, values), result), new KeyValues());
    }

    /**
     * Converts the `KeyValues` map into an plain object.
     *
     * @returns
     */
    public toObject(): Record<string, string[]> {
        return Array.from(this.entries()).reduce((result, [key, value]) => ({ ...result, [key]: value.toArray() }), {});
    }
}
