import React, { FC } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { HEADERLOGO } from '@/utils/navigation/navigationData'

type Props = {
    width: number
    height: number
}

const HeaderLogo: FC<Props> = ({ width, height }) => (
    <div className="flex items-stretch self-stretch">
        <Link href={HEADERLOGO.LINK} className="flex flex-none cursor-pointer items-center">
            <Image
                src={HEADERLOGO.IMAGE}
                alt="SPORT1"
                width={width}
                height={height}
                style={{ height, width }}
            />
        </Link>
    </div>
)

export default HeaderLogo
