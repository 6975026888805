import { logEntries$, LogEntry, logGroup, LogLevel } from '@mbrtargeting/metatag-shared-modules';
import { LogGroupOptions } from '@mbrtargeting/metatag-shared-types';
import { cssText } from '@mbrtargeting/metatag-utils';
import { simpleStorageSwitch, simpleUrlSwitch } from '../../utils/devops-options-helper.js';

const logger = logGroup<LogGroupOptions>({ system: 'MetaTag', prefix: '' });

export { logger as log, logEntries$, logger };

class ColorfulConsole {

    #styles: {
        identStyle: string;
        levelStyles: Record<LogLevel, string>;
        metaStyle: string;
        messageStyle: string;
    };

    constructor() {
        // some shared styling
        const baseCss: Partial<CSSStyleDeclaration> = { display: 'inline-block', marginRight: '2px', padding: '2px 4px', borderRadius: '5px' };

        // prepare all styles to make logging faster
        this.#styles = {
            identStyle: cssText({ ...baseCss, color: '#fff', backgroundColor: '#005276' }),
            levelStyles: {
                [LogLevel.DEBUG]: cssText({ ...baseCss, color: '#000', backgroundColor: '#bfdf6a' }),
                [LogLevel.INFO]: cssText({ ...baseCss, color: '#000', backgroundColor: '#00cccd' }),
                [LogLevel.NOTICE]: cssText({ ...baseCss, color: '#fff', backgroundColor: '#0951fb' }),
                [LogLevel.DEPRECATED]: cssText({ ...baseCss, color: '#000', backgroundColor: '#F84DF8' }),
                [LogLevel.WARNING]: cssText({ ...baseCss, color: '#000', backgroundColor: '#ffac14' }),
                [LogLevel.ERROR]: cssText({ ...baseCss, color: '#000', backgroundColor: '#ff7878' }),
                [LogLevel.CRITICAL]: cssText({ ...baseCss, color: '#000', backgroundColor: '#ff5050' }),
                [LogLevel.ALERT]: cssText({ ...baseCss, color: '#fff', backgroundColor: '#ff2828' }),
                [LogLevel.EMERGENCY]: cssText({ ...baseCss, color: '#fff', backgroundColor: '#f00050' }),
            },
            metaStyle: cssText({ ...baseCss, color: '#666', backgroundColor: '#fff' }),
            messageStyle: '',
        };
    }

    /**
     * Extract the caller source line from a standard stack trace.
     *
     * TODO: The format of the stack trace is browser dependent and may not work in non-chrome-based browsers.
     *
     * @param stack a stack trace string
     * @param logLevel the log level to determine the correct line number; for DEPRECATED logs, we want to log the line of the caller of the deprecated function
     * @returns a source line in the format "file:line:column"
     */
    #getSourceLineFromStack(stack: string, logLevel: LogLevel): string {
        const index: number = logLevel === LogLevel.DEPRECATED ? 3 : 2;
        const lines: string[] = stack?.split('\n') ?? [];
        if (lines.length < index + 1) return '';
        // extract data via regex - format is usually: "at functionName (filePath:lineNumber:columnNumber)" or "at filePath:lineNumber:columnNumber"
        const [match, filePath, lineNumber, columnNumber] = lines[index].trim().match(/at\s+(?:.+\s+\()?(.+):(\d+):(\d+)\)?/) ?? [];
        return match ? `${filePath}:${lineNumber}:${columnNumber}` : '';
    }

    public sendLogToConsole({ logLevel, message, messageObjects = [], system = '', stack = '' }: LogEntry): void {
        const { identStyle, levelStyles, metaStyle, messageStyle } = this.#styles;
        const sourceLine = (logLevel >= LogLevel.DEPRECATED) ? this.#getSourceLineFromStack(stack, logLevel) : '';
        window.console?.log?.apply(this, [`%cSDG%c${LogLevel[logLevel].padStart(10)}%c${system.padEnd(10)}%c ${message}`, identStyle, levelStyles[logLevel], metaStyle, messageStyle, ...messageObjects, sourceLine]);
    }
}

// creating a logSink with css styling - assuming all modern browsers support this as we have dropped support for IE
const logSink = new ColorfulConsole();

// reading the wanted console log level from url or storage entry
const consoleLogLevel = +(simpleUrlSwitch(/sdgConsole=([0-9]+)/i) || '') || +(simpleStorageSwitch('sdgDumpLogsToConsole', /^([0-9]+)$/) || '') || LogLevel.ERROR;

// send the logs to the browser console
logEntries$
    .filter(entry => entry.logLevel >= consoleLogLevel)
    .subscribe(entry => logSink.sendLogToConsole(entry));
