import { createCmpProxy } from '@mbrtargeting/metatag-cmp-stub';
import { CmpStatus, EventStatus, TCData, TCFAPI } from '@mbrtargeting/metatag-cmp-types';
import { SystemEvent } from '@mbrtargeting/metatag-shared-types/metatag-core';
import { ConsentCondition, evaluateConsent } from '../../utils/consent-helper.js';
import { addEventListenerWaitForEventStatus, getCustomVendorConsents } from '../../utils/tcfapi.js';
import { inject, injectionTarget } from '../decorators/inject.js';
import { ConfigResolver } from '../essentials/config-resolver.js';
import { selectModuleSettings } from '../essentials/config-selector.js';
import { triggerEvent } from '../essentials/events.js';
import { logger } from '../essentials/logger.js';
import { CONFIG_RESOLVER } from '../token.js';

const log = logger.logGroup({ prefix: 'CMP' });

declare var window: Window & TCFAPI;

export const canAccessTopWindow = () => {
    try {
        return !!(window.top?.location.href);
    } catch (e) {
        return false;
    }
};

@injectionTarget()
export class ConsentCache {

    @inject(CONFIG_RESOLVER) #configResolver!: ConfigResolver;

    #tcDataPromise: Promise<TCData | undefined>;
    #tcData?: TCData;

    #customVendorConsents?: sourcepoint.VendorConsents;

    constructor() {
        const inIframe = !canAccessTopWindow() || window.self !== window.top;
        // if we are in a iframe and __tcfapi not present there
        //  - try to find cmp via __tcfapiLocator and
        //  - create cmp proxy to communicate via postMessage with top window __tcfapi
        if (inIframe && !window.__tcfapi) {
            createCmpProxy();
        }

        addEventListenerWaitForEventStatus([EventStatus.CMP_UI_SHOWN])
            // send legacy events which might still be used on some sites
            .then(() => {
                triggerEvent(SystemEvent.SDG_CMP_CHOICE_UI_SHOWN, {});
            })
            // prevent duplicate __tcfapi error logs as we handle this in tcDataPromise
            .catch(() => { });

        addEventListenerWaitForEventStatus([EventStatus.USER_ACTION_COMPLETE])
            // send legacy events which might still be used on some sites
            .then(() => {
                triggerEvent(SystemEvent.SDG_CMP_CHOICE_SELECTED, {});
                triggerEvent(SystemEvent.SDG_CHOICE_SELECTED, {});
            })
            // prevent duplicate __tcfapi error logs as we handle this in tcDataPromise
            .catch(() => { });

        const tcDataPromise = this.#tcDataPromise = addEventListenerWaitForEventStatus([EventStatus.TC_LOADED, EventStatus.USER_ACTION_COMPLETE])
            // if __tcfapi is not present we get an error which can be handled here by creating a dummy tcData object
            .catch(() => {
                log.fatal('__tcfapi is not present, tcData can not be loaded');
                // depending of CONSENTMANAGER module is active or not:
                //   - if active=false we set gdprApplies=false to continue to load ads for websites which want ignore the TCF framework
                //   - if active=true we set gdprApplies=true to behave like a DENY-TO-ALL consent which stops loading ads at all
                const { active } = this.#configResolver.get(selectModuleSettings('CONSENTMANAGER')) || {};
                return Promise.resolve(createDummyTCData({ gdprApplies: active }));
            })
            // when we received a tcData object or created dummy as fallback, log it and store it in this.tcData
            .then(tcData => {
                log.debug('tcData %o', [tcData]);
                return this.#tcData = tcData;
            });

        // errors in getCustomVendorConsents should not break the tcDataPromise; that's why its chained separately here
        const customVendorConsentsPromise = tcDataPromise
            // for sourcePoint (cmpId=6) we can query for customVendorConsents after tcData is available
            .then(tcData => tcData?.cmpId === 6 ? getCustomVendorConsents() : undefined)
            // when we were able to get customVendorConsents, log it and store it in this.customVendorConsents
            .then(customVendorConsents => {
                log.debug('customVendorConsents %o', [customVendorConsents]);
                return this.#customVendorConsents = customVendorConsents;
            })
            .catch(() => {
                log.error('could not get customVendorConsents');
            });

        // fire events to inform other modules that consent information is available;
        // wait for tcDataPromise and customVendorConsentsPromise to happen before to make sure we have all data available
        Promise.allSettled([tcDataPromise, customVendorConsentsPromise])
            .then(() => {
                triggerEvent(SystemEvent.SDG_CMP_CACHED_CONSENT_AVAILABLE, {});
                triggerEvent(SystemEvent.SDG_CMP_FULL_CONSENT_AVAILABLE, {});
            });
    }

    public getTcData(): TCData | undefined {
        return this.#tcData;
    }

    /**
     * Wait for user to interact with cmp, return IAB TCF data.
     * TCdata can either be recovered from prior user decision, as soon as CMP loads, or will wait until user interacted with CMP layer.
     */
    public async getTcDataPromise(): Promise<TCData | undefined> {
        return this.#tcDataPromise;
    }

    /**
     * Evaluates given consent conditions in the moment of invocation.
     * (tcData might not be available yet)
     *
     * @param conditions an array of conditions to evaluate
     * @returns true, of one of the conditions is evaluated to true
     */
    public ifConsentConditions(conditions: ConsentCondition[]): boolean {
        return evaluateConsent(this.#tcData, conditions);
    }

    /**
     * Evaluates given consent conditions when tcData is available.
     *
     * @param conditions an array of conditions to evaluate
     * @returns a promised true, if one of the conditions evaluated to true
     */
    public async whenConsentConditions(conditions: ConsentCondition[]): Promise<boolean> {
        return this.#tcDataPromise.then(tcData => evaluateConsent(tcData, conditions));
    }

    /**
    * Check if Consent is given for a Custom Vendor.
    *
    * @param vendorName official name of the vendor
    * @returns true if user gave consent, false otherwise
    */
    public getCustomVendorConsent(vendorName: string): boolean {
        return !!this.#customVendorConsents?.consentedVendors?.find(vendor => vendor.name === vendorName);
    }
}

/**
 * Creates a empty TCData object with no-consent default values.
 *
 * @param tcData selective properties to override
 * @returns a dummy TCData object
 */
const createDummyTCData = (tcData?: Partial<TCData>): TCData => ({
    eventStatus: EventStatus.TC_LOADED,
    cmpStatus: CmpStatus.ERROR,
    isServiceSpecific: true,
    gdprApplies: true,
    publisherCC: 'AA',
    useNonStandardTexts: false,
    purposeOneTreatment: false,
    purpose: {
        consents: {},
        legitimateInterests: {},
    },
    vendor: {
        consents: {},
        legitimateInterests: {},
    },
    specialFeatureOptins: {},
    tcString: '',
    // apply this we get passed in
    ...tcData,
});
