import { ResponsiveType, SpacingsVariants } from '@sport1/news-styleguide/Types'

export const TOP_DISPLAY_HEIGHT = 657

export const TOP_MEDIA_MAX_WIDTH = 968
export const TOP_MEDIA_MAX_WIDTH_MOBILE = 468
export const TOP_MEDIA_MAX_HEIGHT = 546
export const TOP_MEDIA_MAX_HEIGHT_MOBILE = 263

export const TEASER_CARD_MAX_ITEMS = 9
export const WITHOUT_RIGHT_COLUMN_TEASER_CARD_MAX_ITEMS = 10
export const EDITORIAL_TEASER_CARD_MAX_ITEMS = 10
export const PAGING_COMPONENT_SIZE = 20

export enum HeadlineTypeEnum {
    popular = 'popular',
}

export const HEADLINE_TYPE_MAP: Map<string | undefined, string | undefined> = new Map([
    [HeadlineTypeEnum.popular, 'Oft gesucht'],
])

export const DEFAULT_OG_IMAGE = '/images/sport1-default-og.jpg'

export const GLOBAL_LAYOUT_COMPONENT_SPACE = [
    'spacing-7',
    'spacing-9',
    'spacing-10',
] as ResponsiveType<keyof SpacingsVariants>

export const GLOBAL_LAYOUT_COMPONENT_SPACE_CLASSNAME = 'mx-16 tn:mx-24 sm:mx-32'
