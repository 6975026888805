export const PaywallConfig = {
    merchantUUID: '62193f14-6fec-45c4-879f-e0fcf0dac676',
    assets: {
        sport1plus: {
            id: '139768',
            brandId: '2301',
        },
        esports1: {
            id: '139773',
            brandId: '2301',
        },
    },
    oauthAppKey: '2c12aa13e082e7cafa95ab457fe28689',
    language: 'de',
}
