import { AdvertJson, ExternalBuildAdOptions } from '@mbrtargeting/metatag-shared-types/ad-engine';
import { AdvertisingApi, InfotoolAPI, PublisherAPI, SystemEvent } from '@mbrtargeting/metatag-shared-types/metatag-core';
import { processAndReplaceQueue, waitForDocumentReady } from '@mbrtargeting/metatag-utils';
import { Config } from '../../interfaces/interfaces.js';
import { getAllModuleRegistrationMetadata } from '../decorators/on-module-registration.js';
import { getAllSystemEventMetadata } from '../decorators/on-system-event.js';
import { InjectionToken } from '../di/injection-token.js';
import { Registry } from '../di/registry.js';
import { CONFIG_RESOLVER, METATAG } from '../token.js';
import { ConfigResolver } from './config-resolver.js';
import { triggerEvent, waitForEvent } from './events.js';
import { logger } from './logger.js';
import { createQueuePublisherAPI } from './publisher-api.js';

const log = logger.logGroup({ prefix: 'SYSTEM' });

export class MetaTag {

    public get version() {
        return __METATAG_VERSION__;
    }

    public registry?: typeof Registry;
    public Publisher: PublisherAPI;
    public Advertising?: AdvertisingApi;
    public Infotool?: InfotoolAPI;

    #deferred: Function[] = [];

    constructor(public cmd: Function[]) {
        if (window.Cypress) {
            this.registry = Registry;
        }
        Registry.register(METATAG, this);
        this.Publisher = createQueuePublisherAPI(cmd);
        waitForEvent(SystemEvent.SDG_CORE_FILE_LOADED, () => {
            processAndReplaceQueue(cmd);
        });
        waitForEvent(SystemEvent.SDG_CONFIG_FILE_AVAILABLE, () => {
            processAndReplaceQueue(this.#deferred);
        });
        waitForDocumentReady(document, () => {
            triggerEvent(SystemEvent.SDG_DOM_CONTENT_LOADED, {});
        });
    }

    /**
     * updates the tagmanager config and starts the tagmanager by registering modules and adding necessary script to the site
     */
    public configure(config: Config): void {
        Registry.register(CONFIG_RESOLVER, new ConfigResolver(config));
        triggerEvent(SystemEvent.SDG_CONFIG_FILE_AVAILABLE, {});
    }

    /**
     * Registers a metatag module.
     *
     * @param moduleName Alias for the module
     * @param Class The class with the module implementation.
     */
    public registerModule<Class extends Object>(moduleName: InjectionToken<any>, Class: { new(...args: any[]): Class }): void {
        const invoke = (obj: any, methodName: string): void => {
            try {
                obj[methodName]();
            } catch (e) {
                log.fatal('unhandled error invoking %s on %o', [methodName, obj]);
                throw e;
            }
        };
        this.#deferred.push(() => {
            log.debug('Registering module %o', [moduleName]);

            const instance = new Class();

            // process all `@onModuleRegistration` decorators
            for (const { methodName } of getAllModuleRegistrationMetadata(instance)) {
                invoke(instance, methodName);
            }

            // process all `@onSystemEvent` decorators and register event listeners
            for (const [methodName, metadataArray] of Object.entries(getAllSystemEventMetadata(instance) || {})) {
                for (const { eventName, options } of metadataArray) {
                    waitForEvent(eventName, () => {
                        invoke(instance, methodName);
                    }, options);
                }
            }

            Registry.register(moduleName, instance);
        });
    }

    /**
     * Will be overwritten from module "LoadAdLibrary" as soon as Core loads, and point to the actual implementation of AdLibrary package
     * @param jsonData {AdvertJson}
     * @param callback {Function}
     */
    public buildAd(jsonData: AdvertJson, options: ExternalBuildAdOptions): void {
        log.debug('AdLibrary not registered yet.');
    }
}
