import React, { FC, useCallback } from 'react'
import TVProgramLib from '@sport1/news-styleguide/TVProgram'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { BaseTVProgramProps } from '@/components/TVProgram/types'

const BaseTVProgram: FC<BaseTVProgramProps> = ({ epgData, selectedDate }) => {
    const createHref = useCallback(
        (channel: { channelId: number; channelName: string; streamingUrl?: string }): string => {
            if (channel.channelName === 'SPORT1') {
                return 'https://tv.sport1.de/live/sport1'
            } else if (channel.channelName === 'SPORT1PLUS') {
                return 'https://tv.sport1.de/live/sport1plus'
            } else if (channel.channelName === 'ESPORTS1') {
                return 'https://www.esports1.de'
            }
            return ''
        },
        []
    )

    return (
        <NonFlexingContainer
            marginLeft={['spacing-none', 'spacing-none', 'spacing-7', 'spacing-10']}
            spaceTop="standard"
            backgroundColor="pearl"
            testID="base-tv-program-container"
        >
            <TVProgramLib
                channels={epgData.channels}
                createBroadcastHref={createHref}
                selectedDate={selectedDate}
                testID="program"
            />
        </NonFlexingContainer>
    )
}

export default BaseTVProgram
