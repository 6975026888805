import React from 'react'

type NavigationMeta = {
    path: string
}

export type NavigationItemServer = {
    tagId?: number
    url: string
    urlType?: 'LAYOUT' | 'EXTERNAL'
    caption: string
    meta: NavigationMeta
}

type ContextValueTV = NavigationItemServer[]

const NavigationContextTV = React.createContext<ContextValueTV>([])

export { NavigationContextTV }
