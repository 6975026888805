export const DISPLAY_ROUTE_CONFIG = {
    SPORT1: {
        TITLE: 'SPORT1.de',
        LINK: 'https://www.sport1.de',
    },
    SPORT1TV: {
        TITLE: 'SPORT1 TV',
        LINK: '/',
    },
}

export const NAVBARICONCONFIG = {
    LIVE: {
        TITLE: 'Ergebnisse',
        LINK: 'https://www.sport1.de/live',
        IMAGE: 'live',
    },
    VIDEOS: {
        TITLE: 'Videos',
        LINK: 'https://www.sport1.de/tv-video',
        IMAGE: 'media',
    },
    TV: {
        TITLE: 'TV',
        LINK: '/',
        IMAGE: 'tv',
    },
}
export const NABVARMOBILECONFIG = {
    BURGERMENU: {
        IMAGE: 'burger-menu',
    },
    CLOSE: {
        IMAGE: 'close-x',
    },
    LIVE: {
        TITLE: 'Ergebnisse',
    },
    VIDEOS: {
        TITLE: 'Videos',
    },
    TV: {
        TITLE: 'TV',
    },
}

export const HEADERLOGO = {
    LINK: 'https://www.sport1.de',
    IMAGE: '/icons/navigation/sport1-logo-onDark.svg',
}

export const LOGINCONFIG = {
    LINK: '/login',
    IMAGE: 'account',
}
