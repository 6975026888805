import { useRouter } from 'next/router'
import { NavigationItemServer } from '@/utils/navigation/TV'

const useCurrentRoute = (
    navigationItems: NavigationItemServer[]
): NavigationItemServer | undefined => {
    const router = useRouter()
    let slug = '/'
    const { slug: querySlug } = router.query as { slug?: string | string[] }

    if (querySlug) {
        if (Array.isArray(querySlug)) {
            slug = slug.concat(querySlug.join('/'))
        } else {
            slug = slug.concat(querySlug)
        }
    }

    const currentNavItem = navigationItems.find(
        (navItem: NavigationItemServer) => navItem.meta.path === slug
    )

    return currentNavItem
}

export default useCurrentRoute
