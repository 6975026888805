import React, { FC } from 'react'
import NavbarIcon from './NavbarIcon/NavbarIcon'
import { NAVBARICONCONFIG } from '@/utils/navigation/navigationData'

type Props = {
    hideTitle?: boolean
}

const NavbarIconMenu: FC<Props> = ({ hideTitle }) => (
    <div className="flex h-full flex-row bg-black">
        <NavbarIcon
            src={NAVBARICONCONFIG.LIVE.IMAGE}
            title={NAVBARICONCONFIG.LIVE.TITLE}
            hideTitle={hideTitle}
            size="23px"
            link={NAVBARICONCONFIG.LIVE.LINK}
        />
        <NavbarIcon
            src={NAVBARICONCONFIG.VIDEOS.IMAGE}
            title={NAVBARICONCONFIG.VIDEOS.TITLE}
            hideTitle={hideTitle}
            size="18px"
            link={NAVBARICONCONFIG.VIDEOS.LINK}
        />
        <NavbarIcon
            src={NAVBARICONCONFIG.TV.IMAGE}
            title={NAVBARICONCONFIG.TV.TITLE}
            hideTitle={hideTitle}
            size="20px"
            link={NAVBARICONCONFIG.TV.LINK}
            selected
        />
    </div>
)

export default NavbarIconMenu
