import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import Touchable from '@sport1/news-styleguide/Touchable'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import SnackBar from '@sport1/news-styleguide/SnackBar'
import FontelloIcon from '@sport1/news-styleguide/FontelloIcon'
import { CallbackEvent, InplayerPaywallType } from '@/components/Paywall/types'
import useScript from '@/hooks/useScript'
import { PaywallConfig } from '@/utils/paywall/paywallConfig'

type SnackbarType = 'LOGOUT' | 'REGISTER' | 'LOGIN'

export const snackBarTextMap: Map<SnackbarType, string> = new Map([
    ['LOGOUT', 'Du hast dich erfolgreich ausgeloggt'],
    ['REGISTER', 'Du hast dich erfolgreich registriert'],
    ['LOGIN', 'Du hast dich erfolgreich eingeloggt'],
])

const AccountButton: FC = () => {
    const [snackbarType, setSnackbarType] = useState<SnackbarType | undefined>()
    const paywallRef = useRef<InplayerPaywallType | undefined>()
    const initial = useRef<boolean>(true)

    const [inplayerLoaded, setInplayerLoaded] = useState(
        useScript('https://assets.inplayer.com/paywall/v3/paywall.min.js', () =>
            setInplayerLoaded(true)
        )
    )

    const showSnackbar = useCallback((type: SnackbarType) => {
        setSnackbarType(type)
        setTimeout(() => setSnackbarType(undefined), 3000)
    }, [])

    const accountInit = useCallback(() => {
        paywallRef.current = new window.InplayerPaywall(PaywallConfig.merchantUUID, [], {
            hideUserMenu: true,
            oauthAppKey: PaywallConfig.oauthAppKey,
            language: PaywallConfig.language,
        })
        paywallRef.current.setLanguage(PaywallConfig.language)
        paywallRef.current.on('logout', () => {
            showSnackbar('LOGOUT')
        })
        paywallRef.current.on('authenticated', (event: CallbackEvent) => {
            switch (event.action) {
                case 'login':
                    showSnackbar('LOGIN')
                    break
                case 'register':
                    showSnackbar('REGISTER')
                    break
            }
        })
    }, [showSnackbar])

    useEffect(() => {
        if (initial.current && inplayerLoaded) {
            initial.current = false
            accountInit()
        }
    }, [accountInit, inplayerLoaded])

    const showAccountDialog = useCallback(() => {
        if (paywallRef.current?.showPaywall) {
            paywallRef.current.showPaywall()
        }
    }, [])

    return (
        <div className="fixed bottom-0 right-0 z-20">
            <NonFlexingContainer
                marginBottom={['spacing-7', 'spacing-7', 'spacing-10', '96px']}
                marginRight={['spacing-7', 'spacing-7', 'spacing-10', '96px']}
                alignRight
            >
                {snackbarType ? (
                    <SnackBar
                        backgroundColor={snackbarType === 'LOGOUT' ? 'keshi-3' : 'ORANGE'}
                        text={snackBarTextMap.get(snackbarType) || ''}
                    />
                ) : null}
                <Touchable onPress={showAccountDialog} testID="paywall-button-touchable">
                    <div
                        className="pointer mt-3 flex items-center justify-center rounded-full bg-black"
                        style={{
                            height: 56,
                            width: 56,
                        }}
                    >
                        <FontelloIcon name="account" color="#fff" fontSize="18px" />
                    </div>
                </Touchable>
            </NonFlexingContainer>
        </div>
    )
}

export default AccountButton
