interface Message {
    show: () => void;
    hide: () => void;
    destroy: () => void;
}

const createMessage = (win: Window, textContent: string): Message => {
    const style: Partial<CSSStyleDeclaration> = {
        position: 'fixed',
        left: '0px',
        zIndex: '999999999 !important',
        opacity: '0',
        transition: 'opacity 1s',
        background: '#fff',
        fontFamily: 'arial, helvetica, freesans, sans-serif !important',
        fontSize: '18px !important',
        fontWeight: '200 !important',
        lineHeight: '16px !important',
        color: 'black !important',
        textAlign: 'left !important',
        margin: '5px',
        borderStyle: 'dashed',
        borderWidth: '1px',
        borderColor: '#ff5a10',
        borderRadius: '10px',
        padding: '5px',
    };
    const div: HTMLDivElement = win.document.createElement('div');
    Object.assign(div.style, style);
    div.textContent = textContent;
    div.setAttribute('data-cy', 'sdg-notification');
    win.document.body.insertBefore(div, win.document.body.firstChild);
    return {
        show: () => div.style.opacity = '1',
        hide: () => div.style.opacity = '0',
        destroy: () => div.parentElement?.removeChild(div),
    };
};

const messages: Message[] = [];

export interface NotificationOptions {
    /** the text/message to show in the notification */
    text: string;
    /** how long (in milliseconds) should the text/message be displayed? */
    duration: number;
    /** window to operate on - defaults to current window */
    win?: Window;
}

/** shows an notification as an overlay on the screen */
export const showNotification = ({ text, duration, win = window }: NotificationOptions) => {
    let msg: Message | undefined;
    while (msg = messages.shift()) msg.destroy();

    const message = createMessage(win, text);
    messages.push(message);
    message.show();
    win.setTimeout(() => message.hide(), duration);
    win.setTimeout(() => message.destroy(), duration + 1000);
};
