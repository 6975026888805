/**
 * Collect version information of known libraries which may cause issues.
 */
const createEnvInfo = (win: any = window) => ({
    p: win.Prototype?.Version,
    m: win.MooTools?.version,
    j: win.jQuery?.fn && win.jQuery?.jquery,
    b: (Promise as any)?.version,
});

/**
 * Check if a function is a browser native implementation.
 *
 * @param fn a given function
 * @returns true, if it seems to be a native function
 */
const isNative = (fn: Function) => /\{\s+\[native code\]/.test(Function.prototype.toString.call(fn));

/**
 * Check if JSON.parse and JSON.stringify are working as expected.
 *
 * - Prototype.js overwrites Array.prototype.toJSON results in ""[1,2]"" (string that has " as the first char),
 *   instead of "[1,2]" (string that has '[' as the first char).
 */
const hasJSONIssue = (): boolean => !Array.isArray(JSON.parse(JSON.stringify([[728, 90], [800, 250]])));

/**
 * Check if Function.prototype.bind is a browser native implementation.
 */
const hasBindIssue = () => !isNative(Function.prototype.bind);

/**
 * Check if Array has browser native implementations.
 */
const hasArrayIssue = () => !isNative(Array.prototype.map) || !isNative(Array.prototype.filter) || !isNative(Array.prototype.reduce);

/**
 * Check if Promise is a browser native implementation.
 */
const hasPromiseIssue = () => !isNative(Promise);

/**
 * Detect known incompatibilities produced by some libaries which overwrite native functions.
 *
 * Not using metatag logger here, as we can not rely on anything if issues are present.
 */
export const detectKnownIssues = (c: Console = console) => {
    // JSON maybe overwritten by prototype.js or mootools
    hasJSONIssue() && c.error('[SDG] JSON issue detected!', createEnvInfo());
    // Function.bind maybe overwritten by prototype.js or mootools
    hasBindIssue() && c.error('[SDG] Function.bind issue detected!', createEnvInfo());
    // Array prototype functions maybe overwritten by prototype.js
    hasArrayIssue() && c.error('[SDG] Array issue detected!', createEnvInfo());
    // Promise maybe overwritten by bluebirdjs
    hasPromiseIssue() && c.error('[SDG] Promise issue detected!', createEnvInfo());
}
