import React, { FC } from 'react'
import DisplayRoute from '../HeaderBanner/components/DisplayRoute/DisplayRoute'
import HeaderLogo from '../HeaderBanner/components/HeaderLogo/HeaderLogo'
import LoginButton from '../HeaderBanner/components/LoginButton/LoginButton'
import NavbarIconMenu from '../HeaderNavigation/components/NavbarIconMenu/NavbarIconMenu'
import BurgerMenu from './BurgerMenu'
import { NavigationItemServer } from '@/utils/navigation/TV'

type Props = {
    navigationItems: NavigationItemServer[]
    toggleHidden: () => void
}

const MobileHeader: FC<Props> = ({ toggleHidden, navigationItems }) => {
    return (
        <div>
            <nav className="top-0 z-10 flex h-11 w-full flex-wrap items-center justify-between bg-black">
                <div className="contents sm:flex">
                    <div className="absolute left-0 right-0 px-4 sm:relative">
                        <HeaderLogo width={71} height={18} />
                    </div>
                </div>
                <div className="flex h-full flex-row">
                    <div className="hidden sm:block sm:pr-6">
                        <NavbarIconMenu hideTitle />
                    </div>
                    <div className="absolute sm:relative flex flex-row justify-center h-11 items-center right-0">
                        <LoginButton />
                        <BurgerMenu {...{ toggleHidden }} />
                    </div>
                </div>
            </nav>
            <div className="border border-gray-400 bg-gray-100 py-1 px-5 text-black">
                <DisplayRoute navigationItems={navigationItems} />
            </div>
        </div>
    )
}

export default MobileHeader
