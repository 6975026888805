(Symbol as any).metadata ||= Symbol('Symbol.metadata');

const OnModuleRegistrationSymbol = Symbol('Symbol.sdgOnModuleRegistration');

/**
 * Metadata for `@onModuleRegistration` decorator.
 */
export interface ModuleRegistrationMetadata {
  methodName: string;
}

/**
 * Decorator `@onModuleRegistration()`
 *
 * @param data optional metadata
 * @returns a decorator function
 */
export const onModuleRegistration = () => {
  return function decorator(_target: any, context: ClassMethodDecoratorContext) {
    const metadata = (context.metadata[OnModuleRegistrationSymbol] ||= []) as ModuleRegistrationMetadata[];
    metadata.push({
      methodName: context.name.toString(),
    });
  }
}

/**
 * Returns all `ModuleRegistrationMetadata` on a given target.
 *
 * @param target object to inspect
 * @returns a map of metadata per method
 */
export const getAllModuleRegistrationMetadata = (target: Object) => {
  const metadata: DecoratorMetadataObject = target.constructor[Symbol.metadata] || {};
  return (metadata[OnModuleRegistrationSymbol] || []) as ModuleRegistrationMetadata[];
};
