import React, { FC, ReactElement, useRef } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { ComponentType, LayoutProps, LayoutComponentProps } from '@sport1/types/web'
import dynamic from 'next/dynamic'
import renderBaseChannelComponent from '@/helpers/renderBaseChannelComponent'
import { useAdPlacementContext } from '@/context/AdPlacementContext/AdPlacementProvider'
import renderAdWithPlaceholder from '@/utils/ads/display/renderAdWithPlaceholders'

const TopVideo = dynamic(() => import('@/components/TopVideo'))
const TVArticleSection = dynamic(() => import('@/components/Article/Section/TV'))

type Props = {
    layoutData: LayoutProps
}

const PageContentLayout: FC<Props> = ({ layoutData }) => {
    const dashboardPosition = useRef(1)
    const { firstBillboard } = useAdPlacementContext()

    const renderChannelComponent = (component: LayoutComponentProps): ReactElement | null => {
        let pageItem = null
        switch (component.type) {
            case ComponentType.TOP_VIDEO:
                pageItem = (
                    <div data-testid="topvideo">
                        <NonFlexingContainer
                            marginRight={['spacing-none', 'spacing-none', 'spacing-10', '160px']}
                        >
                            <TopVideo topVideoData={component} meta={layoutData.meta} />
                        </NonFlexingContainer>
                    </div>
                )
                break
            case ComponentType.ARTICLE_SECTION:
                pageItem = <TVArticleSection content={component.content} />
                break
            default:
                pageItem = renderBaseChannelComponent({
                    component,
                    layoutType: 'TV',
                    dashboardPosition,
                })
        }
        return pageItem
    }

    return (
        <>
            {firstBillboard && renderAdWithPlaceholder(firstBillboard)}
            {layoutData.components.map((component: LayoutComponentProps, index: number) => {
                const pageComponent = renderChannelComponent(component)
                if (pageComponent) {
                    return (
                        <NonFlexingContainer
                            key={index}
                            marginTop={
                                index === 0
                                    ? 'spacing-none'
                                    : ['spacing-4', 'spacing-4', 'spacing-4', 'spacing-7']
                            }
                            marginBottom={
                                index < layoutData.components.length - 1
                                    ? ['spacing-4', 'spacing-4', 'spacing-4', 'spacing-7']
                                    : 'spacing-none'
                            }
                        >
                            {pageComponent}
                        </NonFlexingContainer>
                    )
                }
            })}
        </>
    )
}

export default PageContentLayout
