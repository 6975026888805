import { HorizontalCardProps, TeaserCardProps } from '@sport1/types/web'
import {
    EDITORIAL_TEASER_CARD_MAX_ITEMS,
    TEASER_CARD_MAX_ITEMS,
    WITHOUT_RIGHT_COLUMN_TEASER_CARD_MAX_ITEMS,
} from '@/utils/content/constants'

export const prepareTeasers = (teaserComponent: TeaserCardProps | HorizontalCardProps) => {
    if (!teaserComponent?.content || teaserComponent.content?.length === 0) return []
    const { content, hasRightColumn } = teaserComponent
    const isEditorialContent = teaserComponent?.meta?.tracking?.find(
        prop => prop.key === 'component_type' && prop.value === 'EDITORIAL_CONTENT'
    )

    let maxItemsCount = TEASER_CARD_MAX_ITEMS
    if (isEditorialContent) {
        maxItemsCount = EDITORIAL_TEASER_CARD_MAX_ITEMS
    } else if (Object.hasOwn(teaserComponent, 'hasRightColumn') && !hasRightColumn) {
        maxItemsCount = WITHOUT_RIGHT_COLUMN_TEASER_CARD_MAX_ITEMS
    }

    return [
        ...content.slice(
            0,
            Math.min(teaserComponent.numberOfContentsToShow || maxItemsCount, maxItemsCount)
        ),
    ]
}
