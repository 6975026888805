import React, { FC } from 'react'
import LoginButton from './components/LoginButton/LoginButton'
import DisplayRoute from './components/DisplayRoute/DisplayRoute'
import { NavigationItemServer } from '@/utils/navigation/TV'

type Props = {
    navigationItems: NavigationItemServer[]
}

const HeaderBanner: FC<Props> = ({ navigationItems }) => (
    <div className="flex flex-1 items-center justify-between text-white md:h-9">
        <DisplayRoute navigationItems={navigationItems} />
        <LoginButton />
    </div>
)

export default HeaderBanner
