import type { AdSize } from '@mbrtargeting/metatag-shared-types/metatag-core';
import { isArray } from '@mbrtargeting/metatag-utils';

/** Typeguard to check if a given argument x is a positive integer number. */
export const isPositiveInteger = (n: unknown): n is number => Number.isInteger(n) && (n as number) >= 0;
/** Typeguard to check if a given argument x is a `SingleSizeArray` AdSize. (an array of two positive integers). */
export const isSingleSizeArray = (x: unknown): x is [number, number] => isArray(x) && x.length === 2 && x.every(isPositiveInteger);
/** Typeguard to check if a given argument x is a `NamedSize` AdSize. (a string). */
export const isNamedSize = (x: unknown): x is string => x === 'fluid';
/** Typeguard to check if a given argument x is an AdSize (`SingleSizeArray or `NamedSize`). */
export const isAdSize = (x: unknown): x is AdSize => isSingleSizeArray(x) || isNamedSize(x);
/** Typeguard to check if a given argument x is an array of AdSizes. */
export const isAdSizes = (x: unknown): x is AdSize[] => isArray(x) && x.every(isAdSize);

/**
 * A comparator function to check if two Adsizes are identical (comparing inner values, not object instance).
 *
 * @param a an AdSize
 * @param b another AdSize
 * @returns true if both AdSize objects are identical
 */
export const isAdSizeIdentical = (a: AdSize, b: AdSize): boolean => isNamedSize(a) && a === b || isSingleSizeArray(a) && isSingleSizeArray(b) && a[0] === b[0] && a[1] === b[1];

/**
 * A filter generator function to remove Adsizes from an array.
 *
 * @example
 *   [[728,90],[300,250],[160,600]].filter(filterSizes([[160,600]]));
 *     -> [[728,90],[300,250]]
 *
 * @param sizes a AdSize array to remove
 * @returns a new array with removed AdSizes
 */
export const filterSizes = (sizes: AdSize[]) => (size: AdSize) => !sizes.some(s => isAdSizeIdentical(s, size));

/**
 * Filter duplicates from an array of AdSizes.
 * This function is called for each element of an array.
 *
 * @example
 *   [[728,90],[300,250],[300,250]].filter(filterDuplicateSizes);
 *     -> [[728,90],[300,250]]
 *
 * @param size a AdSize to deduplicate
 * @param index the index of the current AdSize element
 * @param sizes the original AdSizes array with all elements
 * @returns an array without duplicates
 */
export const filterDuplicateSizes = (size: AdSize, index: number, sizes: AdSize[]): boolean => index === sizes.findIndex(s => isAdSizeIdentical(s, size));

/** Convert a AdSize into its string representation */
export const toAdSizeString = (size: AdSize): string => isSingleSizeArray(size) ? size.join('x') : size.toString();

/** Convert a array of AdSizes into its string representation  */
export const toAdSizesString = (sizes: AdSize[], separator = ','): string => sizes.map(toAdSizeString).join(separator);
