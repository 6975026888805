import React, { FC } from 'react'
import dayjs from 'dayjs'
import { EventStreamProps, MetaDataProps } from '@sport1/types/web'
import { VideoObject } from 'schema-dts'
import LivestreamPlayer from '@/components/Player/TV'
import LinkedData from '@/components/LinkedData'
import { transformImageUrlToSize } from '@/helpers/imageHelper'
import Sport1Organization from '@/components/LinkedData/Sport1Organization'
import { findValueByKey } from '@/utils/meta/findValue'

type Props = {
    eventStreamData: EventStreamProps
    meta?: MetaDataProps
    testID?: string
}

const hasEventOcurred = (startTime: string): boolean => new Date(Date.now()) > new Date(startTime)

const formatStartTimetoSubtitle = (startTime: string): string => {
    const EUTimeConvert = dayjs(startTime).tz('Europe/Berlin')
    return EUTimeConvert.format(`[Verfügbar am] dddd, D. MMMM [ab] HH:mm [Uhr]`)
}

const formatStartTimetoUploadDate = (startTime: string): string => {
    return dayjs(startTime).subtract(2, 'day').format()
}

const eventStreamToVideoObject = (
    eventStreamData: EventStreamProps,
    meta: MetaDataProps | undefined
): VideoObject => {
    const { startTime, endTime, title, description, imageUrl } = eventStreamData
    const finalTitle = findValueByKey(meta?.seo, 'seo_title') ?? title
    const finalDescription = findValueByKey(meta?.seo, 'seo_description') ?? description ?? title

    return {
        '@type': 'VideoObject',
        name: finalTitle,
        description: finalDescription,
        thumbnailUrl: transformImageUrlToSize(imageUrl, 0, 371),
        // Regard of the topic the uploadDate was set from Seo to be 48 before the startDate
        uploadDate: formatStartTimetoUploadDate(startTime),
        publication: {
            '@type': 'BroadcastEvent',
            name: finalTitle,
            isLiveBroadcast: true,
            startDate: startTime,
            endDate: endTime,
        },
        author: Sport1Organization,
        publisher: Sport1Organization,
    }
}

const EventStream: FC<Props> = ({ eventStreamData, meta, testID }) => {
    const { startTime, title, subTitle, imageUrl, url, meta: eventStreamMeta } = eventStreamData
    const isEventStarted = hasEventOcurred(startTime)
    const linkedData = [eventStreamToVideoObject(eventStreamData, meta)]

    return (
        <>
            <LivestreamPlayer
                videoId={eventStreamData.id as string}
                videoAds={meta?.ad}
                label={isEventStarted ? 'LIVE' : undefined}
                title={title}
                subTitle={isEventStarted ? subTitle : formatStartTimetoSubtitle(startTime)}
                imageUrl={imageUrl}
                videoUrl={url.replace(':authtoken', 'sport2').concat('.m3u8')}
                hidePlayButton={!isEventStarted}
                trackingData={eventStreamMeta?.tracking}
                type="eventStream"
                startTime={new Date(eventStreamData.startTime)}
                endTime={new Date(eventStreamData.endTime)}
                testID={testID}
            />
            <LinkedData linkedData={linkedData} />
        </>
    )
}

export default EventStream
