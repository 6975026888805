import React, { FC, memo, useCallback } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import CardHeadline from '@sport1/news-styleguide/CardHeadline'
import { useRouter } from 'next/router'
import BaseTVProgram from './base'
import { fetchEPGData } from './utils/fetchEPGData'
import { TVProgramData, TVProgramProps } from './types'

const TVProgram: FC<TVProgramProps> = ({ url }) => {
    const router = useRouter()
    const [epgData, setEpgData] = React.useState<TVProgramData>()

    React.useEffect(() => {
        if (!epgData) {
            fetchEPGData(url).then(epg => {
                setEpgData(epg.epgData)
            })
        }
    }, [epgData, url])

    const navigateToProgram = useCallback(() => router.push('/programm'), [router])

    return epgData ? (
        <NonFlexingContainer backgroundColor="pearl">
            <NonFlexingContainer
                marginLeft={['spacing-7', 'spacing-7', 'spacing-7', 'spacing-10']}
                marginRight={['spacing-7', 'spacing-7', 'spacing-7', 'spacing-10']}
                spaceTop="standard"
                backgroundColor="pearl"
            >
                <CardHeadline
                    cardHeadlineType={['cardHeadlineS', 'cardHeadlineM', 'cardHeadlineM']}
                    text="Programm"
                    labelText="Alles anzeigen"
                    onPress={navigateToProgram}
                    testID="125555"
                    rightIconName="scroll-right"
                />
            </NonFlexingContainer>
            <BaseTVProgram epgData={epgData} />
        </NonFlexingContainer>
    ) : null
}

export default memo(TVProgram)
