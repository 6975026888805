import React, { FC } from 'react'
import { GetServerSideProps } from 'next'
import { LayoutProps } from '@sport1/types/web'
import TV404 from './404'
import TVLayout from '@/layouts/TV'
import PageContentLayout from '@/layouts/TV/PageContentLayout'
import Config from '@/utils/Config'
import getJson from '@/utils/fetch/getJson'
import { NavigationItemServer } from '@/utils/navigation/TV'

type TVHomeProps = {
    layoutData?: LayoutProps
    topNavigation: NavigationItemServer[]
}

const meta: { [key: string]: { title: string; description: string } | undefined } = {
    '/live/sport1plus': {
        title: 'SPORT1+ Livestream: 24h Stream des Pay-TV Senders SPORT1+',
        description:
            'SPORT1+ Livestream: Der Pay-TV Sender SPORT1+ im online Live-Stream! Alle Highlights & Sendungen. Jetzt SPORT1+ online schauen!',
    },
    '/programm': {
        title: 'SPORT1 TV Programm: Das Programm von heute | SPORT1',
        description:
            'SPORT1 TV Programm: Was läuft heute auf SPORT1? Alle Infos zum Programm, Sendungen & Livesport! Jetzt das SPORT1 Programm einsehen!',
    },
    '/live/sport1': {
        title: 'SPORT1 Livestream: 24h SPORT1 live online | SPORT1',
        description:
            'SPORT1 Livestream: 24 Stunden, 7 Tage die Woche! Der SPORT1 online Live-Stream mit allen Highlights & Sendungen. Jetzt SPORT1 online schauen!',
    },
}

const Index: FC<TVHomeProps> = ({ topNavigation, layoutData }) => {
    if (!layoutData) {
        return <TV404 topNavigation={topNavigation} />
    }

    return (
        <TVLayout topNavigation={topNavigation}>
            <PageContentLayout layoutData={layoutData} />
        </TVLayout>
    )
}

export const getServerSideProps: GetServerSideProps<TVHomeProps> = async ({ query, res }) => {
    let slug = '/'
    // 1. get slug of context
    slug = slug.concat((query as { slug?: string[] }).slug?.join('/') || '')

    // Default 404, only 200 if we have a layout
    res.statusCode = 404

    // 2. Fetch video navigation
    const navigationData = (await getJson(`${Config.CMS_API}/v3/de/tv/navigation`).catch(
        () => undefined
    )) as { topNavigation: NavigationItemServer[] }
    if (!navigationData) {
        return {
            notFound: true,
            statusCode: res.statusCode,
        }
    }

    const props = {
        topNavigation: navigationData.topNavigation,
        statusCode: 200,
    }

    // 3. filter current page
    const currentPage = navigationData.topNavigation.find(item => item.meta.path === slug)

    // 4. check current page available
    if (!currentPage || currentPage.urlType === 'EXTERNAL') {
        props.statusCode = res.statusCode
        return {
            props,
        }
    }

    // 5. Fetch layout
    const layoutData = await getJson(currentPage.url).catch(() => undefined)
    if (!layoutData) {
        props.statusCode = res.statusCode
        return {
            props,
        }
    }

    res.statusCode = 200
    // add additional meta data
    layoutData.meta = layoutData.meta || {}
    layoutData.meta.tracking = layoutData.meta.tracking || []
    layoutData.meta.data = layoutData.meta.data || []
    layoutData.meta.tracking.push({ key: 'page_withvideo', value: 'true' })
    if (meta[slug]?.title) {
        layoutData.meta.tracking.push({ key: 'page_title', value: meta[slug]?.title })
    }
    if (meta[slug]?.description) {
        layoutData.meta.data.push([
            { key: 'name', value: 'description' },
            { key: 'content', value: meta[slug]?.description },
        ])
    }

    const deeplink = `sport1://live-stream`
    res.setHeader('deeplink', deeplink)

    return {
        props: {
            ...props,
            deeplink,
            layoutData,
        },
    }
}

export default Index
