import React, { FC } from 'react'
import HeaderBanner from './components/HeaderBanner/HeaderBanner'
import HeaderNavigation from './components/HeaderNavigation/HeaderNavigation'
import MobileHeader from './components/MobileHeader'
import { NavigationContextTV } from '@/utils/navigation/TV'

type Props = {
    toggleHidden: () => void
}

const Header: FC<Props> = ({ toggleHidden }) => {
    const navigationItems = React.useContext(NavigationContextTV)

    return (
        <header className="sticky top-0 z-30 bg-black md:static">
            <div className="md:hidden">
                <MobileHeader toggleHidden={toggleHidden} navigationItems={navigationItems} />
            </div>
            <div className="hidden items-start justify-between md:relative md:block md:px-8 lg:px-8">
                <HeaderBanner navigationItems={navigationItems} />
                <HorizontalSpacer />
                <HeaderNavigation navigationItems={navigationItems} />
            </div>
            <style jsx global>{`
                @media all and (min-width: 1024px) {
                    .header__right-panel {
                        width: 290px;
                    }
                }
            `}</style>
        </header>
    )
}

const HorizontalSpacer = () => (
    <div className="absolute left-0 w-full border-b border-white opacity-30"></div>
)

export default Header
