import { loadScript } from '@mbrtargeting/metatag-utils';
import { simpleStorageSwitch, simpleUrlSwitch } from '../../utils/devops-options-helper.js';
import { showNotification } from '../../utils/notification-helper.js';
import { createLongTouchListner } from '../../utils/touch-helper.js';
import { inject, injectionTarget } from '../decorators/inject.js';
import { ConfigResolver } from '../essentials/config-resolver.js';
import { selectCommonSettings } from '../essentials/config-selector.js';
import { logger } from '../essentials/logger.js';
import { CONFIG_RESOLVER } from '../token.js';

const log = logger.logGroup({ prefix: 'INFOTOOL' });

declare var window: Window & {
    showSdgDebug: () => void;
};

@injectionTarget()
export class InfotoolLoader {

    @inject(CONFIG_RESOLVER) #configResolver!: ConfigResolver;

    constructor() {
        // register the window.showSdgDebug() function
        window.showSdgDebug = () => this.loadInfotool();

        // create a touch listener to load the infotool with a 3-seconds-three-finger-touch on mobile devices
        createLongTouchListner({
            touchCount: 3,
            touchDuration: 3000,
            onTouch: () => showNotification({ text: 'wait...', duration: 3000 }),
            onCancel: () => showNotification({ text: 'canceled', duration: 1000 }),
            onLongTouch: () => {
                showNotification({ text: 'loading infotool', duration: 1000 });
                this.loadInfotool();
            }
        });

        // check url and storage for switches to auto show the infotool
        const showInfoTool = !!simpleUrlSwitch(/sdgInfotool=(true|1)/i) || !!simpleStorageSwitch('sdgInfotool', /^(true|1)$/i);
        if (showInfoTool) {
            this.loadInfotool();
        }
    }

    private loadInfotool(): void {
        const { infoToolUrl = '' } = this.#configResolver.get(selectCommonSettings());

        if (!infoToolUrl?.length) {
            log.critical('need infoToolUrl to load info tool!');
            return;
        }

        loadScript({
            src: infoToolUrl,
            node: document.head,
            onload: () => log.debug('infoToolUrl successfully loaded: %s', [infoToolUrl]),
            onerror: () => log.error('infoToolUrl could not be loaded: %s', [infoToolUrl]),
        });
    }
}
