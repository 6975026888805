import { EventStatus, TCData, TCFAPI } from '@mbrtargeting/metatag-cmp-types';
import { isFunction, waitForVariable } from '@mbrtargeting/metatag-utils';

declare var window: Window & TCFAPI & sourcepoint.TCFAPIExtension;

/**
 * tries to lookup the __tcfapi function on the window object.
 * with the help of waitForVariable it will retry until the function is found or the number of retries is reached.
 *
 * @returns Promise<TCFAPI>
 */
const getAPI = () => waitForVariable(100, 20, () => {
    // throw error when api is not found; this error is ignored and triggers a retry as long the number of retries is not reached
    if (!isFunction(window.__tcfapi)) throw new Error('window.__tcfapi not found');
    return window.__tcfapi;
});

export const addEventListenerWaitForEventStatus = async (stati: EventStatus[]): Promise<TCData> => getAPI().then(__tcfapi => new Promise(resolve => {
    // add event listener and wait for a given status to resolve promise
    __tcfapi('addEventListener', 2, (tcData: TCData, success: boolean) => {
        if (success && stati.includes(tcData?.eventStatus)) {
            if (tcData?.listenerId) {
                __tcfapi('removeEventListener', 2, () => { }, tcData.listenerId);
            }
            return resolve(tcData);
        }
    });
}));

export const getCustomVendorConsents = async (): Promise<sourcepoint.VendorConsents> => getAPI().then(__tcfapi => new Promise((resolve, reject) => {
    // call api to get vendorConsents
    __tcfapi('getCustomVendorConsents', 2, (vendorConsents: sourcepoint.VendorConsents, success: boolean) => {
        if (success && vendorConsents) return resolve(vendorConsents);
        return reject(new Error('can not get vendorConsents from __tcfapi'));
    });
}));
