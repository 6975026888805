import React, { FC } from 'react'
import NavItem from './NavItem/NavItem'
import useCurrentRoute from '@/hooks/useCurrentRoute'
import { NavigationItemServer } from '@/utils/navigation/TV'

type Props = {
    navigationItems: NavigationItemServer[]
}

const NavbarMainMenu: FC<Props> = ({ navigationItems }) => {
    const currentNavItem = useCurrentRoute(navigationItems)

    return (
        <nav
            className="overflow-scroll bg-white md:flex md:content-around md:overflow-hidden md:bg-transparent md:py-0"
            role="navigation"
            aria-label="Main Navigation"
            data-testid="navbarMainMenu"
        >
            <span className="hidden">Main Navigation</span>
            <ul className="container m-auto border-b border-gray-200 sm:border-l sm:border-r md:flex md:h-full md:flex-1 md:items-center md:justify-between md:overflow-hidden md:border-0">
                {navigationItems.map((item, index) => {
                    const selected = item.meta.path === currentNavItem?.meta.path
                    return (
                        <NavItem
                            key={`${item.caption}_${index}_${selected}`}
                            item={item}
                            selected={selected}
                            className="px-4"
                        />
                    )
                })}
            </ul>
        </nav>
    )
}

export default NavbarMainMenu
