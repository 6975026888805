import { InjectionToken } from './di/injection-token.js';
import type { ConfigResolver } from './essentials/config-resolver.js';
import type { MetaTag } from './essentials/init-metatag.js';
import type { AdslotRegistry } from './modules/adslot-registry.js';
import type { ConsentCache } from './modules/consent-cache.js';

export const METATAG = new InjectionToken<MetaTag>('MetaTag');

export const ADSLOT_REGISTRY = new InjectionToken<AdslotRegistry>('AdslotRegistry');

export const CONFIG_RESOLVER = new InjectionToken<ConfigResolver>('ConfigResolver');

export const CONSENT_CACHE = new InjectionToken<ConsentCache>('ConsentCache');

export const CORE_FILE_LOADER = new InjectionToken<never>('CoreFileLoader');

export const DEPENDENCY_LOADER = new InjectionToken<never>('DependencyLoader');

export const INFOTOOL_LOADER = new InjectionToken<never>('InfotoolLoader');

export const SOURCEPOINT_LOADER = new InjectionToken<never>('SourcepointLoader');

export const YIELDLOVE_LOADER = new InjectionToken<never>('YieldloveLoader');
