import { logger } from '../loader/essentials/logger.js';

export interface ExtendedPerformanceMarkOptions extends PerformanceMarkOptions {
    once?: boolean;
    increment?: boolean;
    prefix?: string;
}

enum MetaTagPerformanceState {
    IDLE = 'I',
    RUNNING = 'R',
    DONE = 'D',
    MEASURED = 'M',
}

interface MetaTagPerformanceEntry {
    markOptions: ExtendedPerformanceMarkOptions;
    once: boolean;
    state: MetaTagPerformanceState;
    start: () => MeasureMetaTagPerformance;
    end: () => MeasureMetaTagPerformance;
    measure: () => void;
}

export interface MeasurePerformanceOptions {
    prefix?: string;
    once?: boolean;
    log?: boolean;
}

export type MeasureMetaTagPerformance = { start: () => MeasureMetaTagPerformance, end: () => MeasureMetaTagPerformance }

/** a map to memorize how often a markName has been used, starting with 1 */
const markCounts = new Map<string, number>();

/**
 * A prefix for all our measurements as we share the performance marks with the website.
 */
const PREFIX = 'metatag-';
const metatagPerformanceEntries = new Map<string, MetaTagPerformanceEntry>();

/**
 * Simple interface to start measuring performance via browser Performance API.
 * Will allow triggering a "start" and "end" for the measurement. To preserve as many resources as possible, measurements will be calculated after metaTag has run through its phase model => Tracking Phase Ended
 * All measurements which have not been ended until this point, will be ended automatically.
 * @param label
 * @param options
 */
export const measurePerformance = (label: string, options?: MeasurePerformanceOptions): MeasureMetaTagPerformance => {
    //todo how to handle increment & once??
    const { once = true, log = false, prefix = PREFIX } = options ?? {};
    const perfName: string = prefix + label;

    const startName = `${label}-start`;
    const endName = `${label}-end`;

    if (!metatagPerformanceEntries.has(perfName)) metatagPerformanceEntries.set(perfName, {
        markOptions: {
            prefix,
            once,
            increment: false
        },
        state: MetaTagPerformanceState.IDLE,
        once,
        /** start measurement */
        start(): MeasureMetaTagPerformance {
            if (this.state !== MetaTagPerformanceState.IDLE) return facade;
            this.state = MetaTagPerformanceState.RUNNING;
            mark(startName, this.markOptions);
            return facade;
        },
        /** end measurement */
        end(): MeasureMetaTagPerformance {
            if (this.state !== MetaTagPerformanceState.RUNNING) return facade;
            this.state = MetaTagPerformanceState.DONE;
            mark(endName, this.markOptions);
            this.measure();
            return facade!;
        },
        /** end measurement if still running and calculate is elapsed time  */
        measure() {
            this.end();
            if (this.state !== MetaTagPerformanceState.DONE) return;
            this.state = MetaTagPerformanceState.MEASURED;
            const measure = performance.measure(perfName, prefix + startName, prefix + endName);
            if (log) logger.debug(`performance ${measure.name.replace(PREFIX, '')}: ${measure.duration.toFixed(2)} ms`);
        }
    });
    const entry = metatagPerformanceEntries.get(perfName)!;
    const facade: MeasureMetaTagPerformance = {
        start: () => entry.start(),
        end: () => entry.end()
    }
    return facade;
};

/**
 * Will end any running measurements and calculate elapsed time of all performance measurements.
 * Use as failsafe for measurements that potentially could never be ended.
 *
 * Currently unused
export const endAllPerformanceMeasurements = () => {
    for (const [_, entry] of metatagPerformanceEntries) {
        entry.measure();
    }
};

 */

/**
 * Mark a point in time with a given label (gets prefixed).
 *
 * @param label a unique name for a performance mark
 * @param options options for the performance mark
 */
export const mark = (label: string, options?: ExtendedPerformanceMarkOptions): PerformanceMark | undefined => {
    const { once = false, increment = false, prefix = PREFIX, ...markOptions } = options ?? {};
    let markName: string = prefix + label;
    // get the number of times a mark has been made, starting with 1
    const markCount: number = 1 + (markCounts.get(markName) ?? 0);
    // don't make a mark if 'once' is true and a mark has already been made
    if (once && markCount > 1) return;
    // remember the number of times a mark has been made
    markCounts.set(markName, markCount);
    // append a counter to the mark name if 'increment' is true and a mark has been made at least once
    if (increment && markCount > 1) markName += `_n${markCount}`;
    // make the mark via performance API
    return performance.mark(markName, markOptions);
};

/** a central place to configure all sdg speedcurve measurements */
export const markSdg = (label: string, options?: PerformanceMarkOptions): PerformanceMark | undefined => mark(label, {
    ...options,
    prefix: 'sdg_',
    // once: true,
    increment: true,
});
