import { Organization } from 'schema-dts'
import Config from '@/utils/Config'

const Sport1Organization: Organization = {
    '@type': 'Organization',
    image: `${Config.HOST}/favicon-196x196.png`,
    name: 'SPORT1',
    url: Config.HOST,
}

export default Sport1Organization
