/**
 * Will try to match the given regexp against the current url and the top url to extract the first capture group.
 *
 * @param regexp a regular expression to match against
 * @returns the first capture group of the match or undefined if no match was found
 */
export const simpleUrlSwitch = (regexp: RegExp): string | undefined => {
    try {
        const lookup = (w: Window | null) => w?.location?.href.match(regexp)?.[1];
        // if the match is not found in the current window, try to match against the top window
        // keep in mind: the top window might be a different origin, so it will throw an error if we try to access it - the order is important!
        return lookup(window) ?? lookup(window.top);
    } catch (e) {
        return undefined;
    }
}

/**
* Will try to match the given regexp against the sessionStorage and localStorage value of the given key to extract the first capture group.
*
* This does not check consent as we use the switches only for development and debugging purposes.
*
* @param key a given storage key
* @param regexp a regular expression to match against the value
* @returns the first capture group of the match or undefined if no match was found
*/
export const simpleStorageSwitch = (key: string, regexp: RegExp): string | undefined => {
    try {
        const lookup = (storage: Storage) => storage.getItem(key)?.match(regexp)?.[1];
        return lookup(sessionStorage) ?? lookup(localStorage);
    } catch (e) {
        return undefined;
    }
}
