import React, { FC } from 'react'
import { NavigationItemServer } from '@/utils/navigation/TV'
import isSport1 from '@/utils/url/isSport1'

type Props = {
    item?: NavigationItemServer
    selected?: boolean
    className?: string
}

const NavItem: FC<Props> = ({ item, selected, className }) => {
    const isExternal = item?.urlType === 'EXTERNAL'
    const href = !isExternal ? item?.meta.path : item?.url
    return (
        <li
            className={`group flex justify-between text-gray-900 hover:bg-gray-200 hover:text-black md:h-full md:flex-auto md:flex-shrink-0 md:cursor-pointer md:items-center md:px-2 md:text-14 md:font-medium md:text-white ${
                className || ''
            } group`}
            data-testid="navItem"
        >
            <a
                href={href}
                className={`flex items-center border-t border-gray-200 py-3 first:border-t-0 md:border-t-0 md:border-b-2 md:border-transparent md:py-0 md:pt-0 ${
                    selected ? 'md:border-white md:group-hover:border-b-0' : ''
                }`}
                rel={isSport1(href) ? 'follow' : 'nofollow'}
            >
                <span className="md:text-15 font-bold md:w-full md:text-center md:uppercase lg:text-16">
                    {item?.caption}
                </span>
            </a>
        </li>
    )
}

export default NavItem
