export class InjectionToken<T> {

    constructor(
        protected _desc: string,
        options?: {
            factory: () => T;
        },
    ) {
    }

    toString(): string {
        return this._desc;
    }
}
