import { AdSize, PublisherSlotAPI, SlotEvent, SystemSlotEvent } from '@mbrtargeting/metatag-shared-types/metatag-core';
import { isArray, isHTMLElement, isString } from '@mbrtargeting/metatag-utils';
import { isAdSizes, isPositiveInteger } from '../../utils/adsize-helper.js';
import { AdslotStub } from '../adslot-stub.js';
import { inject, injectionTarget } from '../decorators/inject.js';
import { ConfigResolver } from '../essentials/config-resolver.js';
import { selectCommonSettings, selectPositionSettings, selectPositionsObject } from '../essentials/config-selector.js';
import { triggerEvent, triggerHTMLElementEvent } from '../essentials/events.js';
import { logger } from '../essentials/logger.js';
import { CONFIG_RESOLVER } from '../token.js';

const log = logger.logGroup({ prefix: 'AdslotRegistry' });

export type AdslotClass<Class = PublisherSlotAPI> = { new(slotName: string, slotContainer: string | HTMLElement): Class };

@injectionTarget()
export class AdslotRegistry {

    @inject(CONFIG_RESOLVER) #configResolver!: ConfigResolver;

    private adslots: Record<string, PublisherSlotAPI> = {};

    private AdslotClass: AdslotClass = AdslotStub;

    /**
     * Replaces the Adslot-Implementation for all registered and future `adslots`.
     *
     * @param AdslotClass the adslot class to use
     */
    public setAdslotClass(AdslotClass: AdslotClass): void {
        this.AdslotClass = AdslotClass;
        for (const [slotName, stub] of Object.entries(this.adslots)) {
            if (stub instanceof AdslotStub) {
                this.adslots[slotName] = stub.setImpl(AdslotClass);
            }
        }
    }

    /**
     * Creates an Adslot object using the configured `AdslotClass`.
     *
     * @param slotName the name of the ad slot
     * @param slotContainer DOM id of element or element itself to contain the adslot
     * @returns Adslot if successfully created, null otherwise
     */
    public createAdslot(slotName: string, slotContainer: string | HTMLElement): PublisherSlotAPI | null {
        const realSlotName = this.resolveRealSlotName(slotName);
        if (realSlotName !== slotName) {
            log.info('%s: positionOverwrite - using %s as %s', [realSlotName.toLocaleUpperCase(), realSlotName, slotName]);
            slotName = realSlotName;
        }
        if (this.hasAdslot(slotName)) {
            log.alert('%s: Name of ad slot %s has already been used. Ad slots are unique to a page and can only exist once. Try to unregister the slot first to re-use it.', [slotName.toLocaleUpperCase(), slotName]);
            return null;
        }
        const slotConfig = this.#configResolver.get(selectPositionSettings(slotName));
        if (!slotConfig) {
            log.alert('%s: Name of ad slot is unknown.', [slotName.toLocaleUpperCase()]);
            return null;
        }

        return this.registerAdSlot(slotName, slotContainer);
    }

    /**
     * Creates an modular/local AdSlot using the passed parameters and configured `AdslotClass`.
     *
     * @param slotName the name of the ad slot (will be prefixed with modularSlotNamePrefix)
     * @param slotContainer DOM id of element or element itself to contain the adslot
     * @param width the width of the adslot, how it is rendered on the page
     * @param height the height of the adslot, how it is rendered on the page
     * @param adSizes AdSizes that are eligable to display in this slot
     * @param isMobile mobile or desktop slot
     * @param useSubzone zone name
     * @returns Adslot if successfully created, null otherwise
     */
    public createModularAdslot(slotName: string, slotContainer: string | HTMLElement, width: number, height: number, adSizes: AdSize[], isMobile: boolean, useSubzone: string): PublisherSlotAPI | null {
        const { canCreateSlots = false, modularSlotNamePrefix = 'local_' } = this.#configResolver.get(selectCommonSettings());
        const slotNamePrefixed = `${modularSlotNamePrefix}${slotName}`;

        if (!canCreateSlots) {
            log.error('Creating modular slots is not available on this site.');
            return null;
        }
        if (this.hasAdslot(slotNamePrefixed)) {
            log.alert(slotNamePrefixed.toLocaleUpperCase() + ': Name of ad slot %s has already been used. Ad slots are unique to a page and can only exist once. Try to unregister the slot first to re-use it.', [slotName]);
            return null;
        }
        if (!isPositiveInteger(width) || !isPositiveInteger(height)) {
            log.error('%s: The "width" or "height" for this modular adslot is not correct', [slotName]);
            return null;
        }
        if (!isAdSizes(adSizes)) {
            if (isAdSizes([adSizes])) {
                log.error('%s: The "size" for this modular adslot is not a nested array. MetaTag will try to recover your input and convert it. This might lead to problems during ad delivery. Please see our documentation on "local Slots" and how to fix this @ https://stroeerdigitalgroup.atlassian.net/wiki/x/8YYRAw', [slotName]);
                adSizes = [adSizes];
            } else {
                log.error('%s: The "size" for this modular adslot is not an array. Please see our documentation on how to setup "local adslots" @ https://stroeerdigitalgroup.atlassian.net/wiki/x/8YYRAw', [slotName]);
                return null;
            }
        }

        // add to config on-the-fly; after doing that, we can proceed as usual as for other adslots.
        this.#configResolver.update(selectPositionsObject(), positions => ({
            ...positions,
            [slotNamePrefixed]: {
                width: `${width}`,
                height: `${height}`,
                isMobileSlot: !!isMobile,
                showAdvertLabel: true,
                dfpTagType: 'standardGpt' as 'standardGpt',
                isLocalSlot: true,
                reportAs: 'local',
                dfpSizes: adSizes,
                ...(isString(useSubzone) && useSubzone.length > 0) && {
                    dfpPostUnit: useSubzone,
                },
            },
        }));

        return this.registerAdSlot(slotNamePrefixed, slotContainer);
    }

    /**
     * adds slot to registry and fires related event
     */
    private registerAdSlot(slotName: string, slotContainer: string | HTMLElement): PublisherSlotAPI {
        const containerElement: HTMLElement | null = isString(slotContainer) ? document.getElementById(slotContainer) : slotContainer;
        const adslot = this.adslots[slotName] = new this.AdslotClass(slotName, containerElement || slotContainer);

        const eventDetails = {
            passedObject: adslot,
            placement: adslot,
            slot: slotName,
            position: slotName,
        };
        triggerEvent(SystemSlotEvent.SDG_SLOT_REGISTERED, eventDetails);
        //when slotContainer is present at this exact moment, fire register event on it. Make sure that publisher provided input is really an HTMLElement
        if (isHTMLElement(containerElement)) triggerHTMLElementEvent(containerElement, SlotEvent.SLOT_REGISTERED, eventDetails);
        return adslot;
    }

    /**
     * Deletes a adslot from the registry
     *
     * @param slotName a given slotName
     */
    public deleteAdslot(slotName: string): void {
        //todo how to let AdSlotController know?
        //this.adSlotController.queueStates.get(adslot).dispatch(QueueAction.DEREGISTER);
        (this.adslots[slotName] as AdslotStub)?.deconstructor();
        delete this.adslots[slotName];
    }

    /**
     * Deletes all adslots from the registry
     */
    public deleteAllAdslots(): void {
        for (const slotName in this.adslots) {
            this.deleteAdslot(slotName);
        }
    }

    /**
     * Get a list of registered adslots; optionally filtered by given slotNames.
     *
     * @param slotNames optional list of slotNames
     * @returns a list of adslots
     */
    public getAdslots(slotNames?: string[]): PublisherSlotAPI[] {
        if (isArray(slotNames)) {
            return slotNames.map(slotName => this.adslots[slotName]).filter(adslot => adslot);
        }
        return Object.values(this.adslots);
    }

    /**
     * Returns a Adslot by slotName if present
     *
     * @param slotName a given Adslot name
     * @returns an Adslot object or null
     */
    public getAdslot(slotName: string): PublisherSlotAPI | null {
        return this.adslots[slotName] ?? null;
    }

    /**
     * returns all currently registered adslots with name as key
     */
    public getAdslotsMap(): Record<string, PublisherSlotAPI> {
        return { ...this.adslots };
    }

    /**
     * Checks if a Adslot is registered.
     *
     * @param slotName a given Adslot name
     * @returns true if given Adslot name is registered
     */
    public hasAdslot(slotName: string): boolean {
        return !!this.adslots[slotName];
    }

    /**
     * Returns the slotName from postionOverwrite if present, slotName otherwise
     *
     * @param slotName the slotName to find the real name for
     * @returns the real slotName
     */
    private resolveRealSlotName(slotName: string): string {
        const lookup = (name: string, retries: number = 10): string => {
            const alias = this.#configResolver.get(selectPositionSettings(name))?.positionOverwrite;
            return (retries > 0 && alias?.length) ? lookup(alias, retries - 1) : name;
        };
        return lookup(slotName);
    }
}
