import React, { FC, PropsWithChildren } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import Header from './Header'
import MobileNavigation from './Header/components/MobileHeader/MobileNavigation'
import AccountButton from '@/components/Paywall/Button'
import Footer from '@/layouts/Standard/Footer/Footer'
import { NavigationContextTV, NavigationItemServer } from '@/utils/navigation/TV'

type TVLayoutProps = {
    topNavigation: NavigationItemServer[]
}

const TVLayout: FC<TVLayoutProps & PropsWithChildren> = ({ topNavigation, children }) => {
    const [navbarHidden, setNavbarHidden] = React.useState(true)
    const toggleHidden = React.useCallback(() => setNavbarHidden(hidden => !hidden), [])
    return (
        <NavigationContextTV.Provider value={topNavigation}>
            <div className={`relative overflow-hidden ${!navbarHidden ? 'h-screen' : 'w-full'}`}>
                <AccountButton />
                <NonFlexingContainer>
                    <Header toggleHidden={toggleHidden} />
                    <NonFlexingContainer
                        marginY={['spacing-7', 'spacing-7', 'spacing-7', 'spacing-10']}
                        marginLeft={['spacing-none', 'spacing-none', 'spacing-10', '160px']}
                        backgroundColor="tahitian-4"
                    >
                        {children}
                    </NonFlexingContainer>
                    <Footer type="TV" />
                </NonFlexingContainer>
                <MobileNavigation toggleHidden={toggleHidden} hidden={navbarHidden} />
            </div>
        </NavigationContextTV.Provider>
    )
}

export default TVLayout
