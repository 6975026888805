export interface LongTouchListnerParams {
    /** number of touches/fingers required for the listener */
    touchCount: number;
    /** time in milliseconds to epect the touches/fingers to tigger the long-touch */
    touchDuration: number;
    /** function to be called when touchCount fingers touch the screen */
    onTouch: () => void;
    /** function to be called when the touches are released from the screen */
    onCancel: () => void;
    /** function to be called when `touchCount` fingers touch the screen for `touchDuration` milliseconds  */
    onLongTouch: () => void;
    /** optional window to operate on */
    win?: Window;
}

/** creates a listener for a long touch of the screen with `touchCount` fingers */
export const createLongTouchListner = ({ touchCount, touchDuration, onTouch, onCancel, onLongTouch, win = window }: LongTouchListnerParams) => {
    let timer: number | null;
    win.addEventListener('touchstart', (event) => {
        if (event.touches.length === touchCount && !timer) {
            timer = win.setTimeout(() => {
                timer = null;
                onLongTouch();
            }, touchDuration);
            onTouch();
        }
    })
    win.addEventListener('touchend', () => {
        if (timer) {
            win.clearTimeout(timer);
            timer = null;
            onCancel();
        };
    })
}
