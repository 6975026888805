import React, { FC, useCallback, useState } from 'react'
import Touchable from '@sport1/news-styleguide/Touchable'
import FontelloIcon from '@sport1/news-styleguide/FontelloIcon'
import { CallbackData, CallbackEvent, InplayerPaywallType } from '@/components/Paywall/types'
import useScript from '@/hooks/useScript'
import { LOGINCONFIG } from '@/utils/navigation/navigationData'
import { PaywallConfig } from '@/utils/paywall/paywallConfig'

const LoginButton: FC = () => {
    const [isLoggedIn, setIsLoggedIn] = React.useState<boolean | undefined>()
    const paywallRef = React.useRef<InplayerPaywallType | undefined>()
    const initial = React.useRef<boolean>(true)

    const [inplayerLoaded, setInplayerLoaded] = useState(
        useScript('https://assets.inplayer.com/paywall/v3/paywall.min.js', () =>
            setInplayerLoaded(true)
        )
    )

    const handlePaywallInit = useCallback(() => {
        paywallRef.current = new window.InplayerPaywall(PaywallConfig.merchantUUID, [], {
            oauthAppKey: PaywallConfig.oauthAppKey,
            language: PaywallConfig.language,
            hideUserMenu: true,
        })
        paywallRef.current.setLanguage(PaywallConfig.language)
        paywallRef.current.on('authenticated', () => setIsLoggedIn(true))
        paywallRef.current.on('logout', () => setIsLoggedIn(false))
        paywallRef.current.on('init', (type: CallbackEvent, data: CallbackData) =>
            setIsLoggedIn(data.isAuthenticated)
        )
    }, [])

    React.useEffect(() => {
        if (initial.current && inplayerLoaded) {
            initial.current = false
            handlePaywallInit()
        }
    }, [handlePaywallInit, inplayerLoaded])

    const toggleLoginState = useCallback(() => {
        if (paywallRef.current) {
            if (isLoggedIn && paywallRef.current.logoutUser) {
                paywallRef.current.logoutUser()
            } else if (!isLoggedIn && paywallRef.current.showPaywall) {
                paywallRef.current.showPaywall()
            }
        }
    }, [isLoggedIn])

    if (isLoggedIn === undefined) {
        return null
    }

    return (
        <div className="flex items-center">
            <Touchable onPress={toggleLoginState}>
                <div className="flex-column pointer flex items-center">
                    <div className="hidden tn:block">
                        <FontelloIcon name={LOGINCONFIG.IMAGE} color="pearl" fontSize="15px" />
                    </div>
                    <p className="px-2 text-14 font-bold uppercase text-white">
                        {isLoggedIn ? 'Ausloggen' : 'Einloggen'}
                    </p>
                </div>
            </Touchable>
        </div>
    )
}

export default LoginButton
