import React, { ReactElement } from 'react'

/**
 * Render JSON-LD
 * (it is for SEO)
 * https://json-ld.org/
 */
export default function LinkedData({
    linkedData,
}: {
    linkedData?: Array<object | null> | null
}): ReactElement | null {
    if (!linkedData) {
        return null
    }

    const filtered = linkedData.filter(Boolean)
    if (0 === filtered.length) {
        return null
    }

    return (
        <>
            {filtered.map((ld, key) => (
                <React.Fragment key={key}>
                    <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{
                            __html: JSON.stringify({
                                '@context': 'https://schema.org',
                                ...ld,
                            }),
                        }}
                    />
                </React.Fragment>
            ))}
        </>
    )
}
