import { SystemEvent } from '@mbrtargeting/metatag-shared-types/metatag-core';
import { WaitOptions } from '../essentials/events.js';

(Symbol as any).metadata ||= Symbol('Symbol.metadata');

const OnSystemEventSymbol = Symbol('Symbol.sdgOnSystemEvent');

interface OnSystemEventMetadata {
    eventName: SystemEvent;
    options?: WaitOptions;
}

/**
 * Decorator `@onSystemEvent()`
 *
 * @param eventName SystemEvent
 * @returns a decorator function
 */
export const onSystemEvent = (eventName: SystemEvent, options?: WaitOptions) => {
    return function decorator(_target: any, context: ClassMethodDecoratorContext) {
        const metadata = (context.metadata[OnSystemEventSymbol] ||= {}) as Record<string | symbol, OnSystemEventMetadata[]>;
        (metadata[context.name] ||= []).push({ eventName, options });
    }
};

/**
 * Returns all `SystemEventMetadata` on a given target.
 *
 * @param target object to inspect
 * @returns a map of eventName per method
 */
export const getAllSystemEventMetadata = (target: Object): Record<string, OnSystemEventMetadata[]> => {
    const metadata: DecoratorMetadataObject = target.constructor[Symbol.metadata] || {};
    return (metadata[OnSystemEventSymbol] || {}) as Record<string | symbol, OnSystemEventMetadata[]>;
};
