import React, { FC } from 'react'
import HeaderLogo from '../HeaderBanner/components/HeaderLogo/HeaderLogo'
import NavbarIconMenu from './components/NavbarIconMenu/NavbarIconMenu'
import NavbarMainMenu from './components/NavbarMainMenu/NavbarMainMenu'
import { NavigationItemServer } from '@/utils/navigation/TV'

type Props = {
    navigationItems: NavigationItemServer[]
}

export const ITEM_LIMIT_LAPTOP = 7
export const ITEM_LIMIT_DESKTOP = 9

const HeaderNavigation: FC<Props> = ({ navigationItems }) => {
    return (
        <div className="flex items-center justify-between md:h-16">
            <div className="flex h-full flex-row">
                <div className="flex md:pr-4 lg:pr-8">
                    <HeaderLogo width={131} height={30} />
                </div>
                <NavbarMainMenu navigationItems={navigationItems} />
            </div>
            <NavbarIconMenu />
        </div>
    )
}

export default HeaderNavigation
