import React, { FC } from 'react'
import useCurrentRoute from '@/hooks/useCurrentRoute'
import { DISPLAY_ROUTE_CONFIG } from '@/utils/navigation/navigationData'
import { NavigationItemServer } from '@/utils/navigation/TV'

type Props = {
    navigationItems: NavigationItemServer[]
}

const DisplayRoute: FC<Props> = ({ navigationItems }) => {
    const currentNavItem = useCurrentRoute(navigationItems)
    const isNotBasePath = currentNavItem?.caption && currentNavItem.meta.path !== '/'

    return (
        <div className="text-12 md:px-1">
            <a href={DISPLAY_ROUTE_CONFIG.SPORT1.LINK}>
                <span className="hover:underline md:font-bold">
                    {DISPLAY_ROUTE_CONFIG.SPORT1.TITLE}
                </span>
            </a>
            {' > '}
            <a href={DISPLAY_ROUTE_CONFIG.SPORT1TV.LINK}>
                {isNotBasePath ? (
                    <span className="hover:underline md:font-bold">
                        {' '}
                        {DISPLAY_ROUTE_CONFIG.SPORT1TV.TITLE}
                    </span>
                ) : (
                    <h1 className="inline hover:underline md:font-bold">
                        {DISPLAY_ROUTE_CONFIG.SPORT1TV.TITLE}
                    </h1>
                )}
            </a>

            {/* Prevents repetition of base path */}
            {isNotBasePath ? (
                <>
                    {' > '}
                    <h1 className="inline md:font-bold">{currentNavItem?.caption}</h1>
                </>
            ) : null}
        </div>
    )
}

export default DisplayRoute
