import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import IconButton from '@sport1/news-styleguide/IconButton'
import React from 'react'
import { SystemSpacingsProps, SpacingsVariants } from '@sport1/news-styleguide/Types'

interface IconButtonProps extends SystemSpacingsProps {
    icon: 'scroll-left' | 'scroll-right'
    onPress: () => void
    testID?: string
    buttonVariant?: 'primary' | 'secondary' | 'tertiary' | 'highlight'
    arrowMargin?: keyof SpacingsVariants
}

const IconButtonArrow = ({
    icon,
    onPress,
    testID,
    buttonVariant,
    arrowMargin,
}: IconButtonProps) => {
    const isLeftArrow = icon === 'scroll-left'

    return (
        <NonFlexingContainer
            testID={testID}
            position="absolute"
            height="100%"
            centerVertical
            left={isLeftArrow ? 0 : undefined}
            right={isLeftArrow ? undefined : 0}
            zIndex={25}
            marginLeft={isLeftArrow ? arrowMargin : 'spacing-none'}
            marginRight={isLeftArrow ? 'spacing-none' : arrowMargin}
        >
            <IconButton
                iconName={icon}
                buttonType="Filled"
                buttonVariant={buttonVariant || 'primary'}
                onPress={onPress}
                testID={`${testID}-icon-${icon}`}
            />
        </NonFlexingContainer>
    )
}

export default IconButtonArrow
