import { detectKnownIssues } from '../utils/issue-helper.js';
import { markSdg } from '../utils/performance.js';
import { MetaTag } from './essentials/init-metatag.js';
import { log } from './essentials/logger.js';
import { AdslotRegistry } from './modules/adslot-registry.js';
import { ConsentCache } from './modules/consent-cache.js';
import { CoreFileLoader } from './modules/core-file-loader.js';
import { DependencyLoader } from './modules/dependency-loader.js';
import { InfotoolLoader } from './modules/infotool-loader.js';
import { SourcepointLoader } from './modules/sourcepoint-loader.js';
import { YieldloveLoader } from './modules/yieldlove-loader.js';
import { ADSLOT_REGISTRY, CONSENT_CACHE, CORE_FILE_LOADER, DEPENDENCY_LOADER, INFOTOOL_LOADER, SOURCEPOINT_LOADER, YIELDLOVE_LOADER } from './token.js';

declare const window: Window & {
    SDG?: MetaTag;
};

markSdg(`script-is-loaded_mt`);

detectKnownIssues();

const SDG = window.SDG = new MetaTag(window.SDG?.cmd || []);
SDG.registerModule(CORE_FILE_LOADER, CoreFileLoader);
SDG.registerModule(SOURCEPOINT_LOADER, SourcepointLoader);
SDG.registerModule(CONSENT_CACHE, ConsentCache);
SDG.registerModule(YIELDLOVE_LOADER, YieldloveLoader);
SDG.registerModule(DEPENDENCY_LOADER, DependencyLoader);
SDG.registerModule(ADSLOT_REGISTRY, AdslotRegistry);
SDG.registerModule(INFOTOOL_LOADER, InfotoolLoader);

log.info('MetaTag2 Loader - Version %s', [__METATAG_VERSION__]);
