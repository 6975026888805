import { InjectionToken } from '../di/injection-token.js';
import { Registry } from '../di/registry.js';

/**
 * Describes a generic constructor of an class.
 */
type Constructor<T = object> = new (...args: any[]) => T;

/**
 * A decorator (mixin) to wrap a given class to support dependency injection.
 *
 * @returns a subclass of the given type
 */
export const injectionTarget = () => {
    return function decorator<Class extends Constructor>(target: Class, context: ClassDecoratorContext<Class>) {
        // noop
    }
};

/**
 * A parameter decorator to mark constructor parameters as to be injected.
 *
 * @param key an optional lookup key of the resource
 * @returns an parameter decorator
 */
export const inject = <Value>(token: InjectionToken<Value>) => {
    return function decorator(_target: any, context: ClassFieldDecoratorContext<unknown, Value>) {
        const fieldName = context.name.toString();

        return (initialValue: Value): Value => {
            try {
                return Registry.lookup(token);
            } catch (e) {
                console.error(`error initializing field %s with dependency %s`, fieldName, token, e);
                return initialValue;
            }
        }
    }
};
