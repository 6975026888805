import type { ModuleObject } from '@mbrtargeting/metatag-config-types';
import { Config } from '../../interfaces/interfaces.js';

export const selectFullConfig = () => (config: Config) => config;

export const selectCommonSettings = () => (config: Config) => config.common;
export const selectLayoutSettings = () => (config: Config) => config.layout;
export const selectFeatureSettings = () => (config: Config) => config.features
export const selectFormats = () => (config: Config) => config.formats;

export const selectTargetingSettings = () => (config: Config) => config.targeting?.common;
export const selectTargetingPixels = () => (config: Config) => Object.entries(config.targeting ?? {}).filter(([key]) => key !== 'common');

export const selectAdserverGamSettings = () => (config: Config) => config.adserver?.dfp;
export const selectAdserverRingierSettings = () => (config: Config) => config.adserver?.ringier;
export const selectAdserverSsspSettings = () => (config: Config) => config.adserver?.sssp;
export const selectAdserverSponsorshipSettings = () => (config: Config) => config.adserver?.sponsorship;

export const selectTemplate = (templateName: string) => (config: Config) => config.templates?.[templateName];

export const selectPositionsObject = () => (config: Config) => config.positions ||= {};
export const selectPositionSettings = (slotName: string) => (config: Config) => config.positions?.[slotName];

export const selectModuleSettings = <ModuleName extends keyof ModuleObject>(moduleName: ModuleName) => (config: Config) => config.modules.map(module => module[moduleName]).find(config => config);

export const selectFirstActiveVideoAdserver = () => (config: Config) => Object.values(config.videoAdserver ?? {}).find(adserver => adserver.active);
export const selectVideoPositions = (platform: 'desktop' | 'mew') => (config: Config) => config.videoPositions?.[platform];
