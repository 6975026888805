import React, { FC } from 'react'
import FontelloIcon from '@sport1/news-styleguide/FontelloIcon'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { PropsType } from '@sport1/news-styleguide/Types'
import isSport1 from '@/utils/url/isSport1'

type Props = {
    src: string
    title: string
    hideTitle?: boolean
    size: PropsType['fontSize']
    link: string
    selected?: boolean
}

const NavbarIcon: FC<Props> = ({ src, title, hideTitle, size, link, selected }) => (
    <a href={link} rel={isSport1(link) ? 'follow' : 'nofollow'}>
        <div
            className={`container m-auto flex h-full cursor-pointer flex-col items-center justify-center px-3 text-white hover:bg-gray-200 hover:text-black md:px-4 ${
                selected && 'bg-gray-900'
            }`}
        >
            <NonFlexingContainer height={34} centerVertical>
                {/* TODO: Using react elements for MVP, change to news-styleguide  */}
                <FontelloIcon name={src} fontSize={size} />
            </NonFlexingContainer>

            {!hideTitle ? (
                <span className="font-bold md:w-full md:text-center md:text-12 md:uppercase">
                    {title}
                </span>
            ) : null}
        </div>
    </a>
)

export default NavbarIcon
