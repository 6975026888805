export type Entry<K, V> = [K, V];

/**
 * Extends the default ES6 Map implementation.
 */
export class Map2<K, V> extends Map<K, V> {

    constructor(
        private factoryfn: (key: K) => V,
        entries?: readonly (readonly [K, V])[] | null,
    ) {
        super(entries);
    }

    /**
     * Gets an value or lazyly creates it if not present.
     *
     * @param key a given key
     * @returns a value from the map or newly created by the value factory
     */
    public get(key: K): V {
        if (!this.has(key)) this.set(key, this.factoryfn(key));
        return super.get(key)!;
    }

    public filter(predicate: ([key, value]: Entry<K, V>) => boolean) {
        return new Map2(this.factoryfn, this.entriesArray().filter(predicate));
    }

    public keysArray(): K[] {
        return [...this.keys()];
    }

    public valuesArray(): V[] {
        return [...this.values()];
    }

    public entriesArray(): Entry<K, V>[] {
        return [...this.entries()];
    }
}
