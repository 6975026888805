import React, { FC, ReactElement } from 'react'
import { TopVideoProps, MetaDataProps } from '@sport1/types/web'
import EventStream from './EventStream'
import Config from '@/utils/Config'
import LivestreamPlayer from '@/components/Player/TV'
import DashboardPresenting from '@/components/DashboardPresenting'

type Props = {
    topVideoData: TopVideoProps
    meta?: MetaDataProps
}

const TopVideo: FC<Props> = ({ topVideoData, meta }) => {
    const renderVideoComponent = (component: TopVideoProps): ReactElement | null => {
        let pageItem
        switch (component.urlType) {
            case 'EVENT_STREAM':
                pageItem = (
                    <EventStream meta={meta} eventStreamData={component} testID="event-stream" />
                )
                break
            case 'LIVE_STREAM':
                pageItem = (
                    <>
                        <LivestreamPlayer
                            videoId={Config.LIVESTREAM_ID}
                            label={component.label}
                            title={component.title}
                            subTitle={component.subTitle}
                            imageUrl={component.imageUrl}
                            videoUrl={component.url.replace(':authtoken', 'sport2').concat('.m3u8')}
                            trackingData={component.meta?.tracking}
                            videoAds={component.meta?.ad}
                            type="liveStream"
                            testID="live-stream"
                        />
                        {component.dashboardPresenting && component.dashboardPresenting?.length > 0
                            ? component.dashboardPresenting.map(item => {
                                  return (
                                      <DashboardPresenting
                                          key={`${item.name}-${component.componentKey}`}
                                          dashboardPresenting={item}
                                          marginBottom="spacing-9"
                                          marginTop="spacing-10"
                                      />
                                  )
                              })
                            : null}
                    </>
                )
                break
        }

        return pageItem
    }
    return renderVideoComponent(topVideoData)
}

export default TopVideo
