import React, { FC } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import Head from 'next/head'
import Image from '@sport1/news-styleguide/Image'
import Link from 'next/link'
import TVLayout from '@/layouts/TV'
import { NavigationItemServer } from '@/utils/navigation/TV'

type Props = {
    topNavigation: NavigationItemServer[]
}

const TV404: FC<Props> = ({ topNavigation = [] }) => (
    <>
        <Head>
            <title> 404 Zu diesem Thema können wir nichts finden :( </title>
        </Head>
        <TVLayout topNavigation={topNavigation}>
            <NonFlexingContainer
                marginLeft={['spacing-9', 'spacing-9', '200px', '300px']}
                marginRight={['spacing-9', 'spacing-9', '200px', '300px']}
            >
                <figure className="relative mb-6 overflow-hidden sm:my-6 sm:w-full sm:rounded-lg">
                    <Image
                        size="M"
                        alt="404"
                        imageUrl="/images/errors/404.jpg"
                        radiusType="rounded"
                    />
                </figure>
                <h1 className="relative mb-6 overflow-hidden text-center  text-42 font-bold sm:my-6 sm:w-full sm:rounded-lg">
                    Zu diesem Thema können wir nichts finden :(
                </h1>
                <Link legacyBehavior href="/">
                    <a>
                        <button className="relative mb-6 w-full overflow-hidden rounded bg-black text-42 font-bold text-white hover:bg-orange sm:my-6 sm:rounded-lg">
                            ZUR HOMEPAGE
                        </button>
                    </a>
                </Link>
            </NonFlexingContainer>
        </TVLayout>
    </>
)

export default TV404
